import Header from '../../components/Header';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function DeleteAccountSuccess() {
  const { t } = useTranslation();

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text mb-0">{t('pages.delete_account_success.request_sent')}</p>
          </Col>
        </Row>
        <Row className="mt-3 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">
              {t('pages.delete_account_success.representative_will_contact')}
            </p>
          </Col>
        </Row>
        <Row className="mt-3 mb-5 mx-3">
          <Col className="text-start">
            <p className="bo-text mb-1">{t('pages.delete_account_success.thank_you')}</p>
            <p className="bo-text">{t('pages.delete_account_success.bridgeover_team')}</p>
          </Col>
        </Row>
        <div className="bo-h-120" />
      </div>
    </Container>
  );
}

export default DeleteAccountSuccess;
